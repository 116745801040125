import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const GiftCardVoucherService = {
  URL: "",

  async init() {
    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-base-uri`);
    this.URL = resp.data;
  },

  GET_VOUCHERS: `api/promo/v1/GiftCardVoucher/campaigns`,
  CREATE_VOUCHER: `api/promo/v1/GiftCardVoucher/campaigns`,
  UPDATE_VOUCHER: campaignId => `api/promo/v1/GiftCardVoucher/campaigns/${campaignId}`,
  DELETE_VOUCHER: campaignId => `api/promo/v1/GiftCardVoucher/campaigns/${campaignId}`,

  createGiftCardVoucher(body) {
    return Vue.axios.post(this.URL + this.CREATE_VOUCHER, body);
  },
  updateGiftCardVoucher(body) {
    return Vue.axios.patch(this.URL + this.UPDATE_VOUCHER(body.id), body);
  },
  getGiftCardVouchers(config) {
    return Vue.axios.get(this.URL + this.GET_VOUCHERS, config);
  },
  deleteGiftCardVoucher(id) {
    return Vue.axios.delete(this.URL + this.DELETE_VOUCHER(id));
  }
};

export default GiftCardVoucherService;
