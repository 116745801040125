var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gift-card-voucher"},[_c('div',{staticClass:"gift-card-voucher__wrapper"},[_c('div',{staticClass:"gift-card-voucher__header"},[_c('GiftCardVoucherForm',{ref:"giftCardVoucherFormFlyout",on:{"save":function($event){return _vm.onFlyoutSave()}}}),_c('v-btn',{staticClass:"ma-2 white--text p-4",attrs:{"color":"green"},on:{"click":function($event){return _vm.onOpenFlyout(null, 'new')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" New gift card voucher ")],1)],1),_c('v-card',{staticClass:"gift-card-voucher__card"},[_c('div',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("common.active"))+" ")]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.activeGiftCardVouchers,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{
          'items-per-page-options': [10, 20, 50, 100],
          showFirstLastPage: true
        },"loading":_vm.activeLoading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.country",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.country.name || "N/A")+" ")]}},{key:"item.startsAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToLocal(item.startsAt))+" ")]}},{key:"item.endsAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToLocal(item.endsAt))+" ")]}},{key:"item.cities",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCityNames(item.cities))+" ")]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onOpenFlyout(item, 'edit')}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-pencil-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteVoucher(item, 'active')}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-delete-forever-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")],1)],1)],1)]}}])}),_c('div',{staticClass:"card-title pt-10"},[_vm._v(" "+_vm._s(_vm.$t("common.archive"))+" ")]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.archiveGiftCardVouchers,"options":_vm.paginationArchive,"server-items-length":_vm.totalArchive,"footer-props":{
          'items-per-page-options': [10, 20, 50, 100],
          showFirstLastPage: true
        },"loading":_vm.archiveLoading},on:{"update:options":function($event){_vm.paginationArchive=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.country",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.country.name || "N/A")+" ")]}},{key:"item.startsAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToLocal(item.startsAt))+" ")]}},{key:"item.endsAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToLocal(item.endsAt))+" ")]}},{key:"item.cities",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCityNames(item.cities) || 'N/A')+" ")]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onOpenFlyout(item, 'edit')}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-pencil-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteVoucher(item, 'archive')}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-delete-forever-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")],1)],1)],1)]}}])})],1)],1),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }