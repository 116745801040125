export const ACTIVE_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.name"),
    value: "name",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("common.validFrom"),
    value: "startsAt",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("common.validTo"),
    value: "endsAt",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("commonTable.country"),
    value: "country",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("commonTable.city"),
    value: "cities",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("common.amount"),
    value: "value",
    sortable: false,
    align: "left"
  },
  {
    text: " ",
    value: "actions",
    width: "3%",
    sortable: false
  }
];

export const ARCHIVE_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.name"),
    value: "name",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("common.validFrom"),
    value: "startsAt",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("common.validTo"),
    value: "endsAt",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("commonTable.country"),
    value: "country",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("commonTable.city"),
    value: "cities",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("common.amount"),
    value: "value",
    sortable: false,
    align: "left"
  },
  {
    text: " ",
    value: "actions",
    width: "3%",
    sortable: false
  }
];
