import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import KTLayoutFlyout from "@/assets/js/layout/extended/quick-flyout.js";
import { VALIDATORS } from "@/common/validators";
import { formatUTCToLocal, formatLocalToUTC } from "@/common/date-format";
import moment from "moment";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import GiftCardVoucherService from "@/core/services/gift-card-voucher.service";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "gift-card-voucher-form",
  data() {
    return {
      title: "New gift card voucher",
      type: "new",
      valid: false,
      rules: {
        required: VALIDATORS.required
      },
      form: {
        id: null,
        name: null,
        selectedCountry: null,
        selectedCity: null,
        startsAt: null,
        endsAt: null,
        value: null
      },
      countries: [],
      cities: []
    };
  },
  async mounted() {
    await SchedulerApiService.init();
    this.getCountries();
  },
  methods: {
    initFlyout() {
      KTLayoutFlyout.init(this.$refs["gift-card-form-flyout"]);
      const el = KTLayoutFlyout.getElement();
      this.flyoutRef = new KTOffcanvas(el);
    },
    async open(obj) {
      this.type = obj?.type || "new";
      this.initFlyout();
      this.flyoutRef.show();

      await this.getCountries();
      await this.getCities(obj.data?.country?.id);
      this.setForm(obj.data);
    },
    close() {
      this.setForm();
      if (this.$refs.startsAt) this.$refs.startsAt.clearHandler();
      if (this.$refs.endsAt) this.$refs.endsAt.clearHandler();

      this.resetForm();
      this.flyoutRef.hide();
    },
    setForm(data) {
      if (!data) return;

      this.form = {
        id: data?.id || null,
        name: data?.name || null,
        selectedCountry: data?.country || null,
        selectedCity: data?.cities || [],
        startsAt: formatUTCToLocal(data?.startsAt) || null,
        endsAt: formatUTCToLocal(data?.endsAt) || null,
        value: data?.value || null
      };
    },
    resetForm() {
      if (!this.$refs?.form) return;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    getCountries() {
      return SchedulerApiService.getCountries().then(res => {
        this.countries = res?.data || [];
      });
    },
    onSelectCountry() {
      const countryId = this.form.selectedCountry?.id;
      this.getCities(countryId);
    },
    async getCities(countryId) {
      if (!countryId) return [];
      const res = await SchedulerApiService.getCities({ countryId });
      this.cities = res?.data || [];
    },
    validateAndSave() {
      if (!this.$refs.form.validate()) return;
      if (!this.form.startsAt || !this.form.endsAt) return;

      const datesValid = moment(this.form.startsAt).isBefore(this.form.endsAt);
      if (!datesValid) {
        this.showDateValidationError(datesValid);
        return false;
      }

      this.save();
    },
    showDateValidationError() {
      const translate = this.$i18n.t("common.validUntil");
      this.$bvToast.toast("Error: " + translate, {
        solid: true,
        variant: "warning",
        autoHideDelay: 1000
      });
      return false; // VALIDATION FAILED, RETURN
    },
    async save() {
      const postModel = this.prepareRequestData();

      let promise = null;
      if (this.type === "edit") {
        promise = GiftCardVoucherService.updateGiftCardVoucher(postModel);
      } else {
        promise = GiftCardVoucherService.createGiftCardVoucher(postModel);
      }

      promise
        .then(response => {
          this.close();
          this.$emit("save", {
            formData: postModel,
            response
          });

          const message = this.$i18n.t("common.savedSuccessfully");
          const variant = "succes";
          this.showToastMessage(message, variant);
        })
        .catch(error => {
          console.log("error =>", error);
          if (error.response && error.response.data && error.response.data.errorCode === 5008) {
            // Handle error with errorCode 5008
            const message = `Error! You cannot create a campaign for the same country where a campaign already exists in that period.`;
            const variant = "warning";
            this.showToastMessage(message, variant, 3000);
          } else {
            // Handle other errors
            const message = `Error! Service currently unavailable`;
            const variant = "error";
            this.showToastMessage(message, variant);
          }
        });
    },
    prepareRequestData() {
      const { selectedCountry, selectedCity, ...postModel } = this.form;

      postModel["country"] = {
        name: selectedCountry.name,
        id: selectedCountry.id
      };

      postModel["cities"] = selectedCity.map(city => ({
        id: city.id,
        name: city.name
      }));

      if (postModel?.startsAt) {
        postModel.startsAt = formatLocalToUTC(postModel.startsAt);
      }

      if (postModel?.endsAt) {
        postModel.endsAt = formatLocalToUTC(postModel.endsAt);
      }

      return postModel;
    },
    showToastMessage(message, variant, delay = 2000) {
      this.$bvToast.toast(message, {
        title: this.$i18n.t("menu.giftCard"),
        solid: true,
        variant: variant,
        autoHideDelay: delay
      });
    }
  },
  computed: {
    isFormValid() {
      const formValid = this.$refs.form?.validate();
      const datesExist = this.form.startsAt && this.form.endsAt;
      return formValid && datesExist;
    }
  }
};
