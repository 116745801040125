import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ACTIVE_HEADERS, ARCHIVE_HEADERS } from "../gift-card-voucher-tables";
import { formatUTCToLocal } from "@/common/date-format";
import GiftCardVoucherForm from "../gift-card-voucher-form/gift-card-voucher-form.vue";
import GiftCardVoucherService from "@/core/services/gift-card-voucher.service";
import ConfirmDialog from "@/view/pages/ConfirmDialog.vue";

export default {
  props: {},
  name: "gift-card-voucher-list",
  components: {
    GiftCardVoucherForm,
    ConfirmDialog
  },
  data() {
    return {
      title: "Gift Card Voucher component",
      activeGiftCardVouchers: [],
      archiveGiftCardVouchers: [],
      pagination: {},
      paginationArchive: {},
      total: 0,
      totalArchive: 0,
      activeLoading: false,
      archiveLoading: false,
      componentLoaded: false
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.componentLoaded) return;
        this.getActiveGiftCardVouchers();
      },
      deep: true
    },
    paginationArchive: {
      handler() {
        if (!this.componentLoaded) return;
        this.getArchiveGiftCardVouchers();
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await GiftCardVoucherService.init();

    this.getActiveGiftCardVouchers();
    this.getArchiveGiftCardVouchers();

    this.componentLoaded = true;
  },
  methods: {
    onOpenFlyout(event, type) {
      const update = {
        data: event ? { ...event } : null,
        type
      };
      this.$refs.giftCardVoucherFormFlyout.open(update);
    },
    onFlyoutSave() {
      this.getActiveGiftCardVouchers();
      this.getArchiveGiftCardVouchers();
    },
    getConfigParams(isActive) {
      return {
        params: {
          isActive: isActive,
          pageNumber: isActive ? this.pagination.page - 1 : this.paginationArchive.page - 1,
          pageSize: isActive ? this.pagination.itemsPerPage : this.paginationArchive.itemsPerPage
        }
      };
    },
    getActiveGiftCardVouchers() {
      const config = this.getConfigParams(true);

      this.activeLoading = true;
      GiftCardVoucherService.getGiftCardVouchers(config)
        .then(res => {
          if (!res.data?.campaigns?.results) return;
          this.activeGiftCardVouchers = res.data.campaigns.results || [];
          this.total = res.data.campaigns?.totalCount || 0;
        })
        .finally(() => {
          this.activeLoading = false;
        });
    },
    getArchiveGiftCardVouchers() {
      const config = this.getConfigParams(false);

      this.archiveLoading = true;
      
      GiftCardVoucherService.getGiftCardVouchers(config)
        .then(res => {
          const campaigns = res.data?.campaigns;
          if (!campaigns?.results) return;
          this.archiveGiftCardVouchers = campaigns.results || [];
          this.totalArchive = campaigns?.totalCount || 0;
        })
        .finally(() => {
          this.archiveLoading = false;
        });
    },
    getCityNames(cities) {
      if (!cities) return [];
      return cities.map(c => c?.name).join(", ");
    },
    async onDeleteVoucher(item, type) {
      const confirmMsg = "Are you sure you want to delete campaign: " + item.name + " ?";

      if (await this.$refs.confirm.open("Confirm", confirmMsg)) {
        GiftCardVoucherService.deleteGiftCardVoucher(item.id).then(() => {
          this.showDeleteMessage();
          const getVouchers =
            type === "active" ? this.getActiveGiftCardVouchers : this.getArchiveGiftCardVouchers;
          getVouchers();
        });
      }
    },
    showDeleteMessage() {
      this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
        title: this.$i18n.t("menu.giftCard"),
        solid: true,
        variant: "success",
        autoHideDelay: 1000
      });
    },
    formatUTCToLocal: formatUTCToLocal
  },
  computed: {
    name() {
      return this.$i18n.t("menu.giftCard");
    },
    headers() {
      return ACTIVE_HEADERS({ i18n: this.$i18n });
    },
    archiveHeaders() {
      return ARCHIVE_HEADERS({ i18n: this.$i18n });
    }
  }
};
